/* .form {
    width: 85%;
    padding-bottom: 5rem;
  }
   */

.substituteImage {
  height: 20vh;
  align-self: flex-end;
  margin-right: 4vw;
  margin-top: 3vh;
}

@media only screen and (min-width: 768px) {
  .substituteImage {
    height: 30rem;
    align-self: center;
    margin-right: 0;
    margin-top: -15vh;
  }
}
