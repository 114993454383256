
.discountedContainer {
  background-color: #4B45AF;
}
.content{
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 3vh 1.2rem;
}

.price {
  margin: 0;
  margin-left: 0.5rem;
}
.plan-type {
  display: flex;
}

.btn {
  margin-top: 0.25rem;
  margin-right: 1rem;
}

.plan-details {
  display: flex;
}

.offer {
  background-color: var(--main-blue);
  color: white;
  padding: 0.32rem .7rem;
  border-radius: 0.6rem;
  font-size: 0.7rem;
  font-weight: 600;
  margin-left: 0.5rem;
  margin-top: -2.5rem;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  text-align: center;
  max-height: 1rem;
  position: absolute;
  right: 10vw;
}

.blue-text {
  color: var(--main-blue);
}

.green-text{
  color: var(--green);
}

.unSelected {
  color: #4F4F4F;
}

/* .caption {
} */

.title {
  font-weight: 900;
}
.divider {
  opacity: 0.3;
  margin: 0 1rem;
}

.footer{
  padding : 1rem;
  display: flex;
  justify-content: space-between;
}

.footer p {
  color:  #F89B62;
}



.label{
  display: flex;
  justify-content: flex-start;
  color: white;
  padding: .5rem .6rem;
}

.label p{
  padding-left: .37rem;
  
}

.oldPrice{
  text-decoration: line-through;
}

@media screen and  (min-width : 720px) {
 .offer{
  margin-top: -2.2rem;
 }
}
