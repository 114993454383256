.control {
    margin: 0;
    margin-right: 1rem;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  
  .description {
    margin: 0;
    padding: .2rem 0;
    font-size: 0.7rem;
    color: #464646;
  }
  
  .label {
    display: flex;
    width: fit-content;
    align-items: flex-end;
    height: 1.2rem;
  }
  
  .label p {
    padding-left: 0.2rem;
    font-weight: bolder;
    color: red
  }
  
  
  
  .control select {
    display: block;
    width: 100%;
    margin-top: .1rem;
  }
  
  .control label {
    font-size: 1rem;
    font-weight: 400;
    color: #464646;
    /* margin-bottom: 0.5rem; */
  }
  
  .control select {
    flex: 3;
    font: inherit;
    padding: 0.57rem 0.45rem;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .control select:focus {
    outline: none;
    border-color: #f89b62;
    background: #fffbf9;
  }
  
  
  .control select ::placeholder ::-ms-input-placeholder {
    color: #ccc;
  }
  
  /* error state */
  
  .control.invalid select {
    border-color: rgb(231, 5, 5);
    background: #fff9f9;
  }
  .control.invalid .description {
    color: rgb(231, 5, 5);
    font-weight: 500;
  }
  
  /* .control .label label {
     font-weight: 450;
  } */
  