.container {
  display: flex;
  padding: 0;
  margin: 0;
}

.imageContainer {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--main-blue);
  justify-content: flex-end;
}

.logo {
  top: 0;
  width: 8vw;
  margin: 3vh 2vw;
  position: absolute;
}

.cover {
  width: 55vw;
  background-color: var(--main-blue);
  padding: 0 5vw;
}

.formContainer {
  height: 100vh;
  width: 40vw;
  flex-grow: 1;
  padding: 0 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.formContainer input, textarea, select {
  background-color: #eeeeee;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--main-blue);
  padding: 3vh 0;
}

.form {
  width: 100%;

}

.logoBlack {
  width: 0;
}

.divider {
  z-index: -1;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Your tablet CSS here */
  .heading {
    font-size: 1.2rem;
    padding: 0vh 0;
  }
}

@media screen and (max-width: 767px) {
  .heading {
    font-size: 1.8rem;
    padding: 1.5vh 0;
  }
  .imageContainer,
  .logo {
    width: 0;
  }
  .logoBlack {
    width: 5rem;
  }

  .formContainer {
    justify-content: center;
  }

  .divider {
    width: 70%;
    opacity: 0.25;
    margin-top: 5vw;
    margin-bottom: 2vw;
  }
}
