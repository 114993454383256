.userForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: .8vh;
}

.errorText{
  width: 100%;
  align-self: center;
  text-align: center;
  margin-top: .5rem;
}
.btn{
  align-self: center;
  margin-top: 2rem;
}




@media (min-width:320px) {

  .userForm{
    padding-top: 1vh;
    gap: .5vh;
  }

}

@media (min-width:412px)  {

  .userForm{
    padding-top: 1.5vh;
    gap: 2.3vh;
  }

}

@media only screen and (min-width: 768px) {
  .userForm{
    padding-top: 2vh;
    gap: 2vh;
  }

}