.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}

.header {
  padding: 0rem;
  width: 100%;
  padding-bottom: .8rem;
}

.header h2 {
  color: var(--main-blue);
}

.dayItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px;
  border: 1px solid var(--main-blue);
  border-radius: 2px;
  color: var(--main-blue);
}

.itemDay {
  text-align: center;
  font-weight: 300;
  font-size: 0.8rem;
}

.itemDate {
  text-align: center;
  font-weight: 400;
  font-size: 0.9rem;
}

.selected {
  background-color: var(--main-blue);
  color: white;
}

@media screen and (min-width: 768px) {
  .content {
    gap: 12px;
  }
}
