.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Card {
  display: flex;
  flex-direction: column;
  padding: 1.2rem 1.5rem;
  width: 80%;
  margin: 16vh auto;
  min-height: 50vh;
}

@media only screen and (min-width: 768px) {
  .Card {
    width: 40%;
    margin: 20vh auto;
  }
}
