.container {
  background-color: white;
  margin: auto;
  width: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.body {
  display: flex;
  flex-direction: column;
  padding: 5rem 3rem; /* Decreased padding for mobile */
  gap: 4vh;
}

.header {
  display: flex;
  align-items: center; /* Center align content */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: space-between;
}

/* Styles for left side of the header */
.headerLeft {
  flex: 1;
  min-width: 0; /* Allow contents to shrink if necessary */
}

/* Styles for left side of the header */
.headerLeft {
  flex: 1;
  min-width: 0; /* Allow contents to shrink if necessary */
}

.divider {
  height: 1vh;
  border: none;
  background-color: var(--green);
  margin: 1rem 0; /* Add some margin for separation */
}

.headingG {
  color: var(--green);
  font-weight: 700;
  text-align: start; /* Center align text */
}

.text{ 
  margin: 0;
  word-wrap: break-word; /* Break words to fit within container */
  white-space: normal; 
}

.text2{ 
   position: absolute;
  word-wrap: break-word; /* Break words to fit within container */
  white-space: normal; 
  font-size: .5rem;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

/* Existing CSS */
.table,
.th,
.td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: start;
  padding: 0.8rem;
}

.ul {
  padding-left: 20px;
  margin: 0; /* Remove default margin */
}

.ulLi {
  list-style-type: disc;
  margin: 0; /* Remove default margin */
}

.fabContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column-reverse; /* Stack the FAB on the bottom */
  position: fixed; /* Make the container sticky */
  bottom: 0;
  right: 0;
  align-items: flex-end;
  overflow: hidden; /* Prevent FAB from overflowing outside container */
}

.fab {
  margin: 2rem;
}

.table {
  font-size: 1rem;
}

/* Responsive CSS */
@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .table {
    width: 100%;
    font-size: 0.7rem;
  }

  .headerLeft {
    flex: 100%; /* Expand to full width on smaller screens */
  }

  .icon {
    max-width: 80px;
    margin-top: 1rem;
    margin-right: 1rem; /* Add margin to separate from text */
  }
  
}

@media (max-width: 480px) {
  /* Adjust the breakpoint as needed */
  .table {
    width: 100%;
    font-size: 0.5rem;
  }
}
