.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #464646;
  font-size: 0.77rem;
}

.headLight {
  font-size: 1rem;
  color: black;
}

.headBold {
  font-weight: 600;
  color: black;
  font-size: 1rem;
}

.header {
  display: flex;
  gap: 1rem;
  align-items: center;
}
