.card {
  background: white;
  border-radius: 1rem;
  margin: -1px;
}

.elevated {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.dark-border {
  border: rgb(71, 71, 71) solid 1px;
}

.light-border {
  border: rgba(0, 0, 0, 0.26) solid 1px;
}


.blue-border{
  border: var(--main-blue)  solid .16rem;
  background-color: #efefff ;
}


.green-border{
  border: var(--green)  solid .16rem;
  background-color: #f8fffc ;
}

.no-border{

}