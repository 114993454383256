.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  bottom: 10vh;
  left: 10%;
  width: 80vw;
  z-index: 100;
  /* overflow: scroll; */
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .modal {
    left: unset;
    right: 10vw;
    width: 30vw;
    padding: 1rem;
  }
}
