.main-header {
  top: 0;
  display: flex;
  left: .5rem;
  position: sticky;
  padding: 0;
  margin-top: 1vh;
  margin-bottom: -7vh;
  
}

.image-container {
  width: 100%;
  align-items: center;
  display: flex;
  gap: 5rem;
  justify-content: center;
  padding: 0 1rem;
}
.image-container img {
  width: 17vw;
}

.back-btn {
  padding: 1rem;
}

.btn-container {
  display: flex;
  align-items: center;
  position: absolute; /* Reposition logo from the natural layout */
  top: 0px;
  z-index: 2;
}

@media (min-width: 768px) {

  .main-header{
    position: absolute;
  }

  .image-container img {
    width: 9vw;
  }

  .btn-container {
    width: 45vw;
  }
  .main-header {
    height: 10vh;
  }
}
