
.button {
    color: white;
    background: #f89b62;
    border: 0;
    border-radius: .55rem;
    overflow: hidden;
    z-index: 2rem;
    padding: .7rem 2rem;
    width: 100%;
    font-weight: 600;
    font-size: larger;
    align-self: center;
  }

  button:disabled,
  button:disabled:hover,
  button:disabled:active {
    background-color: #ccc;
    color: #ffffff;
    border-color: #ccc;
    cursor: not-allowed;
  }