.control {
  margin: 0;
  margin-right: 1rem;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.description {
  margin: 0;
  padding: .2rem 0;
  font-size: 0.7rem;
  color: #464646;
}

.label {
  display: flex;
  width: fit-content;
  align-items: flex-end;
  height: 1.2rem;
}

.label p {
  padding-left: 0.2rem;
  font-weight: bolder;
  color: red
}



.control input {
  display: block;
  width: 100%;
  margin-top: .1rem;
}

.control label {
  font-size: 1rem;
  font-weight: 400;
  color: #464646;
  /* margin-bottom: 0.5rem; */
}

.control input {
  flex: 3;
  font: inherit;
  padding: 0.57rem 0.45rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.control input:focus {
  outline: none;
  border-color: #f89b62;
  background: #fffbf9;
}


.control input ::placeholder ::-ms-input-placeholder {
  color: #ccc;
}


.control.invalid input {
  border-color: rgb(231, 5, 5);
  background: #fff9f9;
}
.control.invalid .description {
  color: rgb(231, 5, 5);
  font-weight: 500;
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

