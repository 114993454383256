.feedback {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 7rem;
  padding: 2rem 0;
}

.loader {
  align-self: center;
  margin: auto;
}

.description {
  text-align: center;
  padding: 1rem 0;
  color: #464646;
}

.btn {
  width: 12rem;
}

.contactDetails {
  display: flex;
  padding-bottom: 1rem;
  color: #464646;
  gap: 0.5rem;
  margin-top: 3vh;
}

.waButton {
  background-color: #16BE45;
  border: none;
  border-radius: 2rem;
  color: white;
  text-align: center;
  gap: .3rem;
  text-decoration: none;
  display: flex;
  
  align-items: center;
  font-size: 1rem;
  padding: .5rem;
  cursor: pointer;
}
