.nav{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
  }
  .nav img{
    width: .5rem;
    fill: black
  }
  .img {
    width: 2.2vw;
    height: 2.2vh;
    background: url('https://firebasestorage.googleapis.com/v0/b/pruoo-app.appspot.com/o/website%2Fback_arrow.svg?alt=media&token=4e057393-b54a-4651-8050-a9b878e5a42f') no-repeat center center;
    background-size: contain;
    filter: brightness(0) saturate(0);
  }
  .nav p{
    color: black;
    font-size: 1rem;
    margin-left: .5rem;
  }
  
  @media (min-width: 768px) {

    
  .nav p{
    color: white;
    margin-left: .1rem;
  }

  .img{
    
    filter: brightness(1) saturate(0);
  }


  }