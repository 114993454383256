.outer {
  border-radius: 20rem;
  width: 1rem;
  height: 1rem;
  border: var(--main-blue) solid 2px;
}
.blueOut{
  border: var(--main-blue) solid 2px;
}
.greenOut{
  border: var(--green) solid 2px;
}

.inner {
  box-sizing: border-box;
  border-radius: 20rem;
  width: 1rem;
  height: 1rem;
}

.blueIn{
  border: var(--main-blue) solid .3rem;
}
.greenIn{
  border: var(--green) solid .3rem;
}