.background {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #f4f4f4;
  align-items: stretch;
  justify-content: space-between;
}

.imageContainer {
  flex: 2 4 60em;
  align-self: center;
  display: flex;
  justify-content: center;
}

.image {
  width: 50rem;
  /* height: 100%; */
  margin: auto;
}

.card {
  flex: 2 1 35em;
  background-color: white;
  box-shadow: -10px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
}

.contactInfoForm input {
  background-color: #eeeeee;
}

.title {
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
  text-align: center;
  color: #464646db;
}

.icon {
  width: 6.5rem;
}

.card form {
  width: 100%;
  margin-top: 1rem;
}

.bottomText {
  padding: 2rem 1rem;
  color: #464646db;
}

.boldTitle {
  font-weight: 700;
  padding-top: 3vh;
  font-size: larger;
  color: #242424db;
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .imageContainer {
    flex-basis: 0em;
  }
  .image {
    width: 0;
  }
  .card {
    flex-basis: 100rem;
  }
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .card {
    flex-basis: 100em;
  }
  .image {
    width: 0;
  }
  .imageContainer {
    flex-basis: 0em;
  }
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .image {
    width: 25rem;
  }
  .imageContainer {
    flex-basis: 45em;
  }

  .card {
    flex-basis: 55em;
  }
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .imageContainer {
    flex-basis: 50em;
  }
  .image {
    width: 37rem;
  }
  .card {
    flex-basis: 50em;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
  .imageContainer {
    flex-basis: 55em;
  }
  .image {
    width: 40rem;
  }
  .card {
    flex-basis: 45em;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .imageContainer {
    flex-basis: 65em;
  }
  .image {
    width: 45rem;
  }
  .card {
    flex-basis: 35em;
  }
}
