.greenSpinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  align-self: center;
}
.greenSpinner:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #80C7BC;
  border-color: #80C7BC transparent #80C7BC transparent;
  animation: spinner 1.2s linear infinite;
}

.orangeSpinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  align-self: center;
}
.orangeSpinner:after {
  content: ' ';
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid #f89b62;
  border-color: #f89b62 transparent #f89b62 transparent;
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
