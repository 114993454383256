.container {
    display: flex;
    height: 10vh;
    gap: .5rem;
}
.label{
    font-weight: 600;
    color: #4F4F4F;
}
.input{
    margin-top: .05rem;
}

.inputContainer {
    flex : 1 1 80rem;
    width:  100%;
}
.btn{
    font-weight: 700;
    margin-top: 3.8vh

}

.enable_btn {
    color : #F89B62;
}

.disable_btn{
    color : #AFAFAF;
}

.actionContainer{
     display:  flex;
     justify-content: center;
     align-items: flex-start;
}

.loader {
    margin-top: -1rem;
}