.price-item{
    margin-bottom: 3vh;
}

.title {
    font-size: 1rem;
    margin: 2vh 0;
    color :#464646;
    font-weight: 550;
}

.description{
    margin-top: -1vh;
    font-size: .75rem;
    color: #464646;
}

.loader {
    margin: 6vh auto;
}

.btn{
    margin-top: 2.5vh;
}

@media (min-width: 768px) {
    .description{
        width:85%
    }
  }

  .errorText{
    width: 100%;
    align-self: center;
    text-align: center;
    margin-top: 0;
    color: red;
    font-weight: 500;
  }
  