.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.imageContainer {
  /* padding-top: 6.5vh; */
  height: fit-content;
  background: var(--bg-gradient);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 0;
  gap: 2.5vw;
}

.textContainer {
  padding-left: 1rem;
  flex-wrap: wrap;
  margin-right: -2rem;
  padding-top: 2rem;
  align-self: start;
  margin-top: 2vh;
}

.textContainer2 {
  padding-left: 1rem;
  flex-wrap: wrap;
  margin-right: -2rem;
  width: 0;
  height: 0;
  z-index: -1;
}

.img_titleContainer {
  display: flex;
  gap: 0.2rem;
  padding: 0.2rem 0;
  align-items: baseline;
  background: var(--gradient-text-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mainImage {
  height: 20vh;
  align-self: flex-end;
  margin-right: 4vw;
  margin-top: 3vh;
}

.mainImage2 {
  width: 100%;
  margin-top: -20rem;
  margin-bottom: -10rem;
  align-self: flex-end;
  /* height: 10vh; */
  width: 0;
  height: 0;
  /* z-index: -1; */
}

.img_imumzIcon {
  width: 2.7rem;
}

.form_imumzIcon {
  width: 0;
}

.img_title {
  font-family: "Oleo Script Reg";
  font-size: 1.5rem;
}

.img_subtitle {
  font-weight: 600;
  font-size: 0.5rem;
}

.img_descriptionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  font-size: 0.75rem;
  font-weight: 300;
}

.description_n {
  color: #fff5e9;
}

.highlighted_text {
  color: #d9f6ff;
}

/* *************************** */

.formContainer {
  /* padding: 0 1.8rem; */
  padding: 0 4rem;
  margin-left: -2rem;
  flex: 1 1 0rem;
  display: flex;
  width: 85%;
  padding-bottom: 5rem;
  flex-direction: column;
  background-color: white;
}

.form_title {
  font-weight: 400;
  font-size: 1.5rem;
  padding: 0.5rem 0;
  margin-top: 5vh;
}

.hide {
  width: 0;
  height: 0;
  z-index: -1;
}

.formTitleContainer {
  margin-top: -5vh;
}

@media only screen and (min-width: 768px) {
  .formTitleContainer {
    margin-top: unset;
  }
  .container {
    flex-direction: row;
  }

  .imageContainer {
    height: 100vh;
    padding-top: unset;
    flex-grow: 50rem;
    flex-direction: column;
    justify-content: space-between;
  }

  .formContainer {
    flex-grow: 50rem;
    width: unset;
    height: unset;
    margin-left: unset;
    padding-top: 3rem;
    padding-right: 5rem;
  }

  .textContainer,
  .textContainer2 {
    margin-right: unset;
    align-self: flex-start;
    padding: 3rem;
  }

  .textContainer2 {
    width: unset;
    z-index: unset;
  }

  .img_titleContainer {
    gap: unset;
  }

  .img_title {
    font-size: 3rem;
    font-family: "Oleo Script Bold";
  }

  .img_subtitle {
    font-size: 0.9rem;
    padding-left: 0.8rem;
  }

  .img_descriptionContainer {
    gap: 0.2rem;
    font-size: 1.4rem;
    font-weight: 300;
  }

  .mainImage {
    height: 30rem;
    align-self: center;
    margin-right: 0;
    margin-top: -15vh;
  }

  .mainImage2 {
    margin-top: -25rem;
    width: 100%;
    height: unset;
  }

  .img_imumzIcon {
    width: 0;
  }

  .form_imumzIcon {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: 7rem;
  }

  .form_title {
    margin-top: unset;
  }
}
